import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true);
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
        if (decodedCity === "unknown") {
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData);
            })
            .catch(error => console.error('Fetch error on external API:', error));
        } else {
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase()));
          setCity(decodedCity);
          console.log(data);
        }
      })
      .catch(error => console.error('Fetch error on local API:', error));
  }, []);



  const imageUrls = [
    'https://i.ibb.co/hfwLJDy/cropped-Photo-Jun-04-2024-1-40-25-AM.jpg',
    'https://i.ibb.co/2sJvrJN/cropped-Photo-Jun-04-2024-1-34-09-AM.jpg',
    'https://i.ibb.co/H710tVR/cropped-Photo-Jun-04-2024-1-42-17-AM.jpg',
    'https://i.ibb.co/nwLHt1Z/cropped-Photo-Jun-04-2024-1-41-47-AM.jpg',
    'https://i.ibb.co/0D0206m/cropped-Photo-Jun-04-2024-1-34-53-AM.jpg',
    'https://i.ibb.co/BLhj2zD/cropped-Photo-Jun-04-2024-1-33-14-AM.jpg',
    'https://i.ibb.co/Wtmj6k3/cropped-Photo-Jun-04-2024-1-43-36-AM.jpg',
    'https://i.ibb.co/LPd7LSL/cropped-Photo-Jun-04-2024-1-32-45-AM.jpg',
    'https://i.ibb.co/KDXQDTV/cropped-Photo-Jun-04-2024-1-35-00-AM.jpg',
    'https://i.ibb.co/93Bg321/cropped-Photo-Jun-04-2024-1-33-24-AM.jpg',
    'https://i.ibb.co/7Nk7fFN/cropped-Photo-Jun-04-2024-1-44-40-AM.jpg',
    'https://i.ibb.co/DWQpLFT/cropped-Photo-Jun-04-2024-1-33-36-AM.jpg',
    'https://i.ibb.co/BKDmBkR/cropped-Photo-Jun-04-2024-1-34-43-AM.jpg',
    'https://i.ibb.co/YkM7XL4/cropped-Photo-Jun-04-2024-1-32-55-AM.jpg'
  ];

  return (
    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/bFyWYbL/cropped-Photo-Jun-04-2024-1-33-14-AM-modified.png'/>
      <section>
          <div className='flexRow'>Elvira 🖤 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            Your fav college goth girl currently in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://onlyfans.com/elvirawaifu/c16" id="customButton">
      Send me a message
    </a>

    <p className="exclusiveContent">
    <span>
    Exclusive Content🔥 70% OFF For 10 PEOPLE ONLY <br/><br/>
    Looking for content partners while im travelling, message me on Onlyfans if your close and want to shoot spicy content 👀
              </span>
    </p>
    <Analytics/>
  </div>
  );
}

export default App;
