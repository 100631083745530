import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';
import F from './F';
import H from './H';
import J from './J';
import K from './K';
import L from './L';
import M from './M';
import N from './N';
import O from './O';
import P from './P';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const [timeLeft, setTimeLeft] = useState(10 * 60);


  useEffect(() => {
    setFetchAttempted(true);
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
        if (decodedCity === "unknown") {
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData);
            })
            .catch(error => console.error('Fetch error on external API:', error));
        } else {
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase()));
          setCity(decodedCity);
          console.log(data);
        }
      })
      .catch(error => console.error('Fetch error on local API:', error));
  }, []);


  // Countdown timer logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the countdown timer
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <Router>
    <Routes>
      <Route path="/" element={
            <div className="landing-container">
            {/* Video Background */}
            <img
      src="./images/background3.jpg"
      alt="Background"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -1,
      }}
    />


            {/* Main content */}
            <div className="container">
              <a className="header-logo" href="https://onlyfans.com/elvirawaifu/trial/40wjsfcseksb6yvyyvse7yvh4wums6kg">
                <div className="onlyfans"></div>
              </a>
              <img
                src="https://i.ibb.co/NntRHc3/photo-2024-11-09-22-58-47-modified.png"
                alt="Header Image"
                className="header-image"
              />

              <div className="textContainer">
                <section>
                  <div className="username"><strong>Elvira 🖤</strong></div>
                  <div className="useraccountname">@elvirawaifu</div>

                  <div id="user-status" className="user-status">
                    <div className="status"></div>
                    <div id="available"><strong>Available now</strong></div>
                    <div className="separator"></div>
                    <div className="clock"></div>
                    <div className="respond-time"><strong>Responds in 2 minutes</strong></div>
                  </div>
                </section>
              </div>

              <div className="textContainer">
                <div className="location"></div>
                <span id="location-name"><strong>{city ? `${city} ${region}` : '<3'}</strong></span>
              </div>

              <div className="textContainer2">
                <div className="time-in-current-location">
                  <strong>Hii i’m Elvira! your favorite lil goth gf 🤭 Looking for content partners in {city || 'your city'}.❤️
</strong>
                </div>
              </div>

              <div className="textContainer3">
                <a href="https://onlyfans.com/elvirawaifu/trial/40wjsfcseksb6yvyyvse7yvh4wums6kg" id="customButton">
                  <div className="onlyfans"></div>
                  MESSAGE ME ON MY FREE OF
                </a>
              </div>



              <div className="countdown-block">
                <span className="discount"><strong>FREE NOW </strong></span>
                <span className="timer-end"><strong>ends in</strong> </span>
                <span id="timer"><strong>{formatTimeLeft(timeLeft)}</strong></span>
              </div>

              <Analytics />
            </div>
          </div>

} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/elvirawaifu" element={<G />} />
<Route path="/Come-Talk-To-Me/onlyfans.com/elvirawaifu" element={<F />} />
<Route path="/Im-Looking-For-A-Boyfriend/onlyfans.com/elvirawaifu" element={<F />} />
<Route path="/Message-Me-Here/onlyfans.com/elvirawaifu" element={<H />} />
<Route path="/Message-Me-On/onlyfans.com/elvirawaifu" element={<J />} />
<Route path="/I-Need-A-Boyfriend/onlyfans.com/elvirawaifu" element={<K />} />
<Route path="/Discounted-first-month/onlyfans.com/elvirawaifu" element={<L />} />
<Route path="/I-Want-A-Boyfriend/onlyfans.com/elvirawaifu" element={<M />} />
<Route path="/I-Want-A-Boyfriend/onlyfans.com/elvirawaifu/1" element={<N />} />
<Route path="/I-Want-A-Boyfriend/onlyfans.com/elvirawaifu/2" element={<O />} />
<Route path="/I-Want-A-Boyfriend/onlyfans.com/elvirawaifu/3" element={<P />} />
</Routes>
</Router>
  );
}

export default App;
